import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Logos from "../components/logos";

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { site, markdownRemark } = data; // data.markdownRemark holds your post data
    const { siteMetadata } = site;
    const { frontmatter } = markdownRemark;
    return (
        <Layout>
            <Helmet>
                <title>
                    {frontmatter.title} | {siteMetadata.title}
                </title>
                <meta name="description" content={frontmatter.metaDescription} />
            </Helmet>

            <div className="w-full mx-auto">
                <div className="py-10 sm:pt-16 lg:pt-8 lg:pb-8 lg:overflow-hidden bg-header">
                    <div className="mx-auto max-w-7xl px-4 lg:px-8">
                        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                            <div className=" sm:text-center px-4 lg:px-8 lg:text-left lg:flex lg:items-center">
                                <div className="lg:py-24">
                                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                        <span className="text-base block font-semibold tracking-wide uppercase">Calendar</span>
                                        <span className="pb-3 block text-white sm:pb-5">What's going on?</span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white my-10 sm:my-12">
                <div className=" relative mx-auto max-w-7xl px-4 lg:px-8">
                    <div class="max-w-max lg:max-w-7xl mx-auto">
                        <div className="mt-12 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex justify-center">
                            <iframe title="calendar" src="https://calendar.google.com/calendar/embed?src=3v3e02lspu15lh213g35sne42o%40group.calendar.google.com&ctz=Europe%2FLondon" width="1000" height="600" frameborder="0" scrolling="no"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <Logos />
        </Layout>
    );
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        title
        metaDescription
      }
    }
  }
`;
